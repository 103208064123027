import React, { useState } from 'react';
import './App.scss';
// import { IFormValue } from './model';
import { createDefect } from './requestService';

// Local development use "(SET INLINE_RUNTIME_CHUNK=false) && react-scripts build", for the build 
interface IFormValue {
  name: string,
  severity: string,
  description: string,
  files: any[]
}

function App() {

  const [name, setName] = useState('');
  const [severity, setSeverity] = useState('undefined');
  const [description, setDescription] = useState('');
  const [onFocusSeverity, setOnFocusSeverity] = useState('placeholder_select');
  const [cutSeverity, setCutSeverity] = useState('cut');
  const [nameClassError, setNameClassError] = useState('input-container ic1');
  const [cutNameError, setCutNameError] = useState('cut');
  const [severityClassError, setSeverityClassError] = useState('input-container ic2');
  const [descriptionClassError, setDescriptionClassError] = useState('input-container ic3 h100');
  const [cutDescriptionError, setCutDescriptionError] = useState('cut');
  const [textError, setTextError] = useState('error_text hide');
  const [msgError, setMsgError] = useState('');
  const [defectClass, setDefectClass] = useState('msg_success hide');
  const [queueFiles, setQueueFiles] = useState<any[]>([]);
  const [filesName, setFilesName] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [preventManyClick, setPreventManyClick] = useState(false);
  const [loader, setLoader] = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (preventManyClick) return;
    setPreventManyClick(true);

    if (!name || name === '') {
      setMsgError('Veuillez remplir les champs vides.');
      setNameClassError('input-container ic1 error');
      setCutNameError('cut hide');
      setTextError('error_text');
      setPreventManyClick(false);
    }

    if (!severity || severity === 'undefined') {
      setMsgError('Veuillez remplir les champs vides.');
      setSeverityClassError('input-container ic2 error');
      setCutSeverity('cut_select hide');
      setTextError('error_text');
      setPreventManyClick(false);
    }

    if (!description || description === '') {
      setMsgError('Veuillez remplir les champs vides.');
      setDescriptionClassError('input-container ic3 h100 error');
      setCutDescriptionError('cut hide');
      setTextError('error_text');
      setPreventManyClick(false);
    }

    if (name && severity !== 'undefined' && description) {
      const formValues: IFormValue = {
        name: name,
        severity: severity,
        description: description,
        files: []
      }
      if (queueFiles.length > 0) {
        formValues.files = queueFiles;
      }
      console.log('values:: ', formValues);
      setLoader(true);
      const defect: any = await createDefect(formValues);
      console.log('defect:: ', defect);
      if (!defect.attachmentsError && !defect.error) {
        if (defect.id > 0) {
          setSuccessMsg(`Merci, votre incident numéro ${defect.id} a bien été transmis.`);
          setDefectClass('msg_success display');
          setPreventManyClick(false);
          setLoader(false);
          setName('');
          setSeverity('');
          setDescription('');
        }
      } else if (defect.attachmentsError) {
        setSuccessMsg(`Merci, votre incident numéro ${defect.id} a bien été transmis. Mais il y a eu un problème avec la ou les pièce(s) jointe(s).`);
        setDefectClass('msg_success display');
        setPreventManyClick(false);
        setLoader(false);
        setName('');
        setSeverity('');
        setDescription('');
      } else if (defect.error) {
        setMsgError('Une erreur est survenue. Merci de réessayer plus tard');
        setTextError('error_text');
        setPreventManyClick(false);
        setLoader(false);
      }
    }
  }

  function handleChangeName(event: any) {
    event.preventDefault();
    setName(event.target.value)
  }

  function handleChangeSeverity(event: any) {
    event.preventDefault();
    setSeverity(event.target.value)
  }

  function handleChangeDescription(event: any) {
    event.preventDefault();
    if (event.target.value !== 'undefined') setDescription(event.target.value)
  }

  function handleFocusName(event: any) {
    setNameClassError('input-container ic1');
    setCutNameError('cut');
    setTextError('error_text hide');
  }

  function handleFocusSeverity(event: any) {
    setSeverityClassError('input-container ic2');
    setTextError('error_text hide');
    if (onFocusSeverity === 'placeholder_select') {
      setCutSeverity('cut_select display');
      setOnFocusSeverity('placeholder_select display');
    } else {
      setCutSeverity('cut_select');
      setOnFocusSeverity('placeholder_select');
    }
  }

  function handleBlurSeverity(event: any) {
    setCutSeverity('cut_select');
    setOnFocusSeverity('placeholder_select');
    setSeverityClassError('input-container ic2');
  }

  function handleFocusDescription(event: any) {
    setDescriptionClassError('input-container ic3 h100');
    setCutDescriptionError('cut');
    setTextError('error_text hide');
  }

  async function handleInputFiles(event: any) {
    const queue: any[] = [];
    const files = event.target.files;
    console.log('files:: ', files);
    const queueFileName: string[] = [];
    for(const file of files) {
      const binary = await handleFiles(file);
      queue.push({
        name: file.name,
        data: binary
      });
      if (files.length > 5 || file.name.length > 10) {
        const ext = file.name.split('.')[1]
        queueFileName.push(file.name.substring(0, 5) + '....' + ext);
      } else {
        queueFileName.push(file.name);
      }
    }
    console.log('queue::: ', queue);
    setQueueFiles(queue);
    setFilesName(queueFileName.join(', '));
  }

  function handleFiles(file: any) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      let fileResult;
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        fileResult = e?.target?.result;
        resolve(fileResult);
      }
    });
  }

  return (
    <div className="section">
      <div className="container">
        <div className="form_container">
          <div className="title">Incident PDS</div>
          <div className="subtitle">Créez votre incident de production</div>
          <form onSubmit={handleSubmit}>
            <div className={nameClassError}>
              <input id="name" className="input-text" type="text" placeholder=" " autoComplete="off" value={name} onChange={handleChangeName} onFocus={handleFocusName} />
              <div className={cutNameError}></div>
              <label htmlFor="name" className="placeholder">Titre</label>
            </div>
            <div className={severityClassError}>
              <select id="severity" value={severity} onClick={handleFocusSeverity} onBlur={handleBlurSeverity} onChange={handleChangeSeverity}>
                <option value="undefined" disabled>Sévérité</option>
                <option value="1-Mineur">Mineur</option>
                <option value="2-Majeur">Majeur</option>
                <option value="3-Bloquant">Bloquant</option>
              </select>
              <div className={cutSeverity}></div>
              <label htmlFor="severity" className={onFocusSeverity}>Sévérité</label>
            </div>
            <p className="info"> 
              <span>Merci d'indiquer votre identité et vos coordonnées dans le champ "Description".</span><br />
              <span>Veuillez également décrire l'incident rencontré, puis cliquer sur "Envoyer".</span><br />
              <span>Les équipes plateforme de services réceptionneront l'incident et vous recontacteront au plus tôt.</span>
            </p>
            <div className={descriptionClassError}>
              <textarea id="description" className="input-text h100" placeholder=" " autoComplete="off" value={description} onChange={handleChangeDescription} onFocus={handleFocusDescription} ></textarea>
              <div className={cutDescriptionError}></div>
              <label htmlFor="description" className="placeholder">Description</label>
            </div>
            <div className="input_file_container">
              <input className="input_file" type="file" id="files" name="files" accept="image/*" multiple onChange={handleInputFiles}></input>
              <label htmlFor="files" className="label_file">Charger un ou plusieurs fichier(s)</label>
              {queueFiles.length > 0 ? <label className="label_file_length">{queueFiles.length} fichier(s)</label> : ''}
              {queueFiles.length > 0 ? <label className="label_file_length">{filesName}</label> : ''}
            </div>
            <div>
              <p className={textError}>{msgError}</p>
              <p className={defectClass}>{successMsg}</p>
              <input type="submit" className="submit" value="Envoyer" />
            </div>
            { loader ? <div  className="loaderFullcontainer">
              <div className="loaderFull">
                <div className="loader"></div>
                <div className="loader-txt">Veuillez patientez...</div>
              </div>
            </div> : '' }
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;