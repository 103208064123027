import fetch from 'node-fetch';

export async function createDefect(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        try {
            // fetch('http://localhost:5000/api/create', { change the proxy in the package json for local development
            fetch('https://incident.askjacques.com/api/create', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            // .then(res => res.text())
            .then(res => res.json())
            .then(body => {
                console.log(body);
                resolve(body);
            });
        } catch(err) {
            console.error('[RequestService]::[createDefect]::Error:: ', err);
            reject(err);
        }
    });
}